// Your settings div/button
export const showMoreButton =
  " text-blue w-full hover:cursor-pointer hover:text-orange-dark flex justify-between border-blue items-center pt-3 pb-4 group peer transition-all "
// Save or send information buttons (blue)
export const saveButton =
  " border-2 border-blue p-1 px-3 pt-1 pb-2 mr-8 mb-4 font-sans text-blue hover:text-white hover:bg-100 transition-all duration-100 bg-no-repeat to-blue from-blue bg-gradient-to-t "
// Save or send information buttons (white)
export const saveButtonWhite =
  " border-2 border-white p-1 px-3 pt-1 pb-2 my-2 font-sans text-white hover:text-blue hover:bg-100 transition-all duration-100 bg-no-repeat to-white from-white bg-gradient-to-t "
// Save or send information buttons (orange)
export const saveButtonOrange =
  " border-2 border-orange-dark p-1 px-3 pt-1 pb-2 mr-8 mb-4 font-sans text-orange-dark hover:text-white hover:bg-100 transition-all duration-100 bg-no-repeat to-orange-dark from-orange-dark bg-gradient-to-t "
// Delete changes or decline button
export const underlineButton =
  " relative font-sans text-blue after:absolute after:inset-0 after:border-b-2 after:border-current after:transition-transform hover:text-orange-dark pt-1 pb-2 after:-translate-y-1 hover:after:translate-y-0 "
//Chip buttons
export const chipButton =
  " border-2 border-blue p-1 px-3 pt-1 pb-1 font-sans text-blue hover:text-white hover:bg-100 hover:border-blue transition-all duration-100 bg-no-repeat to-blue from-blue bg-gradient-to-t "
//Go to a new page button. Button with a href.
export const GoToPageButton =
  " border-2 border-blue p-1 px-3 pt-1 pb-2 mb-4 font-sans text-blue hover:text-white hover:bg-100 transition-all duration-100 bg-no-repeat to-blue from-blue bg-gradient-to-t "
// Blue go to button
export const blueButton =
  " border-2 border-blue bg-blue p-1 px-3 pt-1 pb-2 font-sans text-white hover:text-blue hover:bg-100 transition-all duration-100 bg-no-repeat to-gray from-gray bg-gradient-to-t "
//Go to case and create new case button
export const caseButton =
  " border-2 border-blue p-1 px-3 pt-1 pb-2 font-sans text-blue hover:text-white hover:bg-100 transition-all duration-100 bg-no-repeat to-blue from-blue bg-gradient-to-t "
// Only button 1 active
export const notificationButton =
  " border-b-2 border-white p-1 px-3 pt-1 pb-2 my-2 font-sans text-base text-white hover:text-blue hover:bg-100 transition-all duration-100 bg-no-repeat to-white from-white bg-gradient-to-t w-full"
// Button 1 and 2
export const notificationButtonUnderline =
  " relative font-sans text-white text-lg after:absolute after:inset-0 after:border-b-2 after:border-current after:transition-transform hover:text-orange-dark pt-1 pb-2 after:-translate-y-1 hover:after:translate-y-0 "
// Underline button for notification
export const notificationButton3 =
  " relative font-sans text-white text-sm after:absolute after:inset-0 after:border-b-2 after:border-current after:transition-transform hover:text-orange-dark pt-1 pb-2 after:-translate-y-1 hover:after:translate-y-0 "
//hoverDirectionStyles
export const right = " bg-x-0 bg-right"
export const left = " bg-x-0 bg-left"
export const down = " bg-y-0 bg-bottom"
export const up = " bg-y-0 bg-top"
