import React, { Component, useEffect, useRef } from "react"
import { useState } from "react"
import { fetchApi, patchApi, postApi } from "../Utils/WebApi-utils"
import { useMsal } from "@azure/msal-react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import axios from "axios"
import { authToken } from "../Security/authToken"
import { Controller, useForm } from "react-hook-form"
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { underlineButton, down, right, saveButton } from "../Styling/Buttons"
import "../Styling/styles.css"
import LoadingModal from "../Modal/LoadingModal"
import {
  InputCase,
  InputCaseError,
  inputField,
  inputFieldError,
  inputFieldErrorMessage,
  selectValue,
  selectValueError,
} from "../Styling/InputField"
import { navigate } from "gatsby"
import { GetProfile } from "../Utils/ReactQueries"

export type UploadFile = {
  name: string
  type: string
}

export type FileAttachment = {
  name: string
  mimeType: string
  document: string
}

export const NewCases = () => {
  const { instance, accounts, inProgress } = useMsal()
  const queryClient = useQueryClient()
  const { register, handleSubmit } = useForm()
  const [categoryValue, setCategoryValue] = useState("")
  const [subCategoryValue, setSubCategoryValue] = useState("")
  const [subject, setSubject] = useState("")
  const [message, setMessage] = useState("")
  const [error, setError] = useState(false)
  const [selectedFile, setSelectedFile] = useState<UploadFile>()
  const [fileReader, setFileReader] = useState<any>({})
  const [loading, setLoading] = useState(true)

  //A styled button that clicks on a hidden input to upload files
  const inputRef = useRef(null)

  const onUploadButtonClick = () => {
    inputRef.current.click()
  }

  const GetCategories = (account, inProgress, instance) => {
    return useQuery(
      "getCategories",
      async () => {
        //await new Promise(resolve => setTimeout(resolve, 500))
        const token = await authToken(account, inProgress, instance)
        if (token?.length > 0) {
          return axios
            .get(process.env.GATSBY_APP_WEBAPI + "HefIncident/Categories", {
              headers: {
                "Conent-Type": "application/json",
                "Ocp-Apim-Subscription-Key":
                  process.env.GATSBY_APP_APIM_SUBSCRIPTION_KEY,
                Authorization: "Bearer " + token,
              },
            })
            .then(res => {
              return res.data
            })
        } else {
          return console.log("Fikk ikke token")
        }
      },
      {
        refetchOnWindowFocus: false,
        // enabled: seeCases,
        retry: 3,
      }
    )
  }

  const MyCategories = GetCategories(accounts, inProgress, instance)

  const CreateCase = useMutation(
    async values =>
      postApi(
        process.env.GATSBY_APP_WEBAPI + "HefIncident/me",
        values,
        accounts,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: (data, values) => {
        //queryClient.setQueryData(["profile"], data)
        toast.success("Saken har nå blitt opprettet", {
          bodyClassName: "bg-blue text-white",
          theme: "colored",
          style: { backgroundColor: "#004C97" },
        })
        queryClient.invalidateQueries(["getCategories"])
        console.log(data)
        setMessage("")
        setSubject("")
        setCategoryValue("")
        setSelectedFile({
          name: "",
          type: "",
        })
        setFileReader({})
        navigate(`/henvendelser?id=${data}`)
      },
      onError: data => {
        toast.error("Noe gikk galt med saken!")
        setLoading(false)
      },
    }
  )

  let fileAttachment: FileAttachment = {
    name: "",
    mimeType: "",
    document: "",
  }

  const changeHandler = e => {
    if (e.target.files[0] !== undefined) {
      setSelectedFile(e.target.files[0])
      return new Promise((resolve, reject) => {
        let reader = new FileReader()
        reader.readAsDataURL(e.target.files[0])
        reader.onload = function () {
          resolve(reader.result)
        }
        setFileReader(reader)
      })
    }
  }

  const onSubmitIncident = values => {
    let base64: any
    if (fileReader.result != null || fileReader.result != undefined) {
      base64 = fileReader.result
      base64 = base64.split("base64,")[1]
      fileAttachment = {
        name: selectedFile.name,
        mimeType: selectedFile.type,
        document: base64,
      }
    } else {
      fileAttachment = null
    }

    if (
      selectedFile == undefined ||
      (selectedFile.name == "" && selectedFile.type == "")
    ) {
      // If the object have no attachment
      let incidentObject: any = {
        category: categoryValue,
        mainAndSubCategory: categoryValue,
        caseTitle: subject,
        description: message,
        caseOriginCode: "minside",
      }
      // Description, subject and category needs a value
      if (
        incidentObject.category == "" ||
        incidentObject.caseTitle == "" ||
        incidentObject.description == ""
      ) {
        setError(true)
      } else {
        setError(false)
        setLoading(true)
        CreateCase.mutate(incidentObject)
      }
    }
    // If the object have an attachemnt
    else {
      let incidentObjectWithAttachment: any = {
        category: categoryValue,
        mainAndSubCategory: categoryValue,
        caseTitle: subject,
        description: message,
        caseOriginCode: "minside",
        attachment: {
          subject: fileAttachment.name,
          fileName: fileAttachment.name,
          documentBody: fileAttachment.document,
          mimetype: fileAttachment.mimeType,
        },
      }
      // Description, subject and category needs a value
      if (
        incidentObjectWithAttachment.category == "" ||
        incidentObjectWithAttachment.caseTitle == "" ||
        incidentObjectWithAttachment.description == ""
      ) {
        setError(true)
      } else {
        setError(false)
        setLoading(true)
        CreateCase.mutate(incidentObjectWithAttachment)
      }
    }
  }

  //If page is refreshed, wait for categories to get values.
  useEffect(() => {
    if (MyCategories.isSuccess && MyCategories?.data == null) {
      MyCategories.refetch()
    } else {
      setLoading(false)
    }
  }, [MyCategories.isSuccess])

  //Remove error message if all fields have a value
  useEffect(() => {
    if (message != "" && subject != "" && categoryValue != "" && error == true)
      setError(false)
  }, [message, subject, categoryValue])

  return (
    <div>
      <div className="bg-gray px-8 py-8 pb-16  lg:flex lg:justify-center lg:items-center">
        <div className="lg:min-w-[50%] lg:max-w-[50%]">
          {CreateCase.isLoading || MyCategories.isLoading || loading ? (
            <LoadingModal />
          ) : (
            <div>
              <div className="mb-8">
                <h1 className=" font-serif text-blue text-xl font-normal ">
                  Kontakt oss
                </h1>
              </div>
              <div className="flex flex-col">
                <form
                  name="case"
                  method="patch"
                  onSubmit={handleSubmit(onSubmitIncident)}
                >
                  <div className="font-sans flex flex-col space-y-8 mb-4">
                    <div className="font-sans flex flex-col space-y-2">
                      <label className="text-blue font-sans text-sm">
                        Kategori
                      </label>
                      <select
                        onChange={e => setCategoryValue(e.target.value)}
                        className={
                          categoryValue == "" && error == true
                            ? selectValueError
                            : selectValue
                        }
                      >
                        <option
                          key="0"
                          value=""
                          selected
                          style={{ display: "none" }}
                        >
                          Velg en kategori
                        </option>
                        {MyCategories?.data?.map((option, i) => (
                          <>
                            {option?.categoryLevel == 778380000 ? (
                              <option
                                key={option?.categoryId}
                                value={option?.categoryId}
                              >
                                {option?.categoryName}
                              </option>
                            ) : null}
                          </>
                        ))}
                      </select>
                    </div>
                    <div className="font-sans flex flex-col space-y-2">
                      <label className="text-blue font-sans text-sm">
                        Emne
                      </label>
                      <input
                        type="string"
                        className={
                          subject == "" && error == true
                            ? inputFieldErrorMessage
                            : inputField
                        }
                        placeholder="Emne"
                        onChange={e => {
                          setSubject(e.target.value)
                        }}
                      ></input>
                    </div>
                    <div className="font-sans flex flex-col space-y-2">
                      <label className="text-blue font-sans text-sm">
                        Melding
                      </label>
                      <div>
                        <div className="flex flex-col">
                          <textarea
                            placeholder="Skriv her..."
                            className={
                              message == "" && error == true
                                ? InputCaseError
                                : InputCase
                            }
                            onChange={e => {
                              setMessage(e.target.value)
                            }}
                            value={message}
                            maxLength={2000}
                          ></textarea>
                          <div className="mt-4">
                            <button
                              type="button"
                              className={
                                " border-2 border-blue p-1 px-3 pt-1 pb-2 mr-8 mb-4 font-sans text-blue fill-current hover:fill-current hover:text-white hover:bg-100 transition-all duration-100 bg-no-repeat to-blue from-blue bg-gradient-to-t flex flex-row items-center " +
                                right
                              }
                              onClick={onUploadButtonClick}
                            >
                              <svg
                                width="22"
                                height="22"
                                viewBox="0 0 22 22"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="mr-2"
                              >
                                <path
                                  d="M19.88 12.9679C21.104 14.1919 21.752 15.5599 21.752 16.9279C21.752 19.3279 19.688 21.4159 17.288 21.4159C15.92 21.4159 14.48 20.7439 13.28 19.5439L1.736 7.99992C0.752 7.01592 0.2 5.76792 0.2 4.59192C0.2 2.69592 2 0.991921 3.968 0.991921C5.168 0.991921 6.248 1.47192 7.256 2.45592L15.44 10.6159C16.208 11.3599 16.616 12.2479 16.616 13.1359C16.616 14.8879 15.2 16.3519 13.472 16.3519C12.632 16.3519 11.768 15.9439 10.952 15.1279L5.984 10.1359C5.816 9.96792 5.768 9.84792 5.768 9.60792C5.768 9.29592 6.008 9.05592 6.296 9.05592C6.536 9.05592 6.68 9.12792 6.848 9.29592L11.84 14.2639C12.392 14.7919 12.92 15.0559 13.424 15.0559C14.552 15.0559 15.344 14.2159 15.344 13.0399C15.344 12.5359 15.08 12.0319 14.552 11.5039L6.368 3.34392C5.648 2.64792 4.832 2.26392 4.064 2.26392C2.72 2.26392 1.448 3.46392 1.448 4.68792C1.448 5.47992 1.88 6.39192 2.6 7.11192L14.168 18.6799C15.176 19.6879 16.184 20.1919 17.216 20.1919C19.04 20.1919 20.504 18.7279 20.504 16.9279C20.504 15.8719 20 14.8639 18.992 13.8559L10.64 5.50392C10.472 5.33592 10.4 5.19192 10.4 4.95192C10.4 4.63992 10.688 4.37592 11 4.37592C11.168 4.37592 11.384 4.47192 11.528 4.61592L19.88 12.9679Z"
                                  fill="currentColor"
                                />
                              </svg>
                              Legg til vedlegg
                            </button>
                            <input
                              id="fileUpload"
                              ref={inputRef}
                              type="file"
                              accept="image/*,.pdf"
                              onChange={changeHandler}
                              hidden
                            />
                            <p className="mb-0">{selectedFile?.name}</p>
                          </div>
                          {error ? (
                            <div className="font-sans text-orange-dark mt-4">
                              <p className="mb-0">
                                Du har glemt å fylle inn noen felter
                              </p>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div>
                      <button type="submit" className={saveButton + down}>
                        Send nå
                      </button>
                      <button
                        type="reset"
                        className={underlineButton}
                        onClick={() => {
                          setMessage("")
                          setSubject("")
                          setCategoryValue("")
                          setSelectedFile({
                            name: "",
                            type: "",
                          })
                          setFileReader({})
                        }}
                      >
                        Forkast
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
